<template>
  <div class="main-panel">
    <h3 class="panel-title" v-if="!layerInfo?.id">部门设置</h3>
    <el-button type="danger"
               size="mini"
               round
               class="save"
               :class="{'update-btn': layerInfo?.id}"
               :loading="isLoading"
               @click="save">保存
    </el-button>
    <el-form :model="form"
             :rules="formRules"
             ref="userForm"
             label-width="65px"
             @submit.prevent>
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item label="名称" prop="name">
            <el-input v-model.lazy.trim="form.name" :size="controlSize" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="仓库" prop="warehouseName">
            <el-input v-model.trim="form.warehouseName" :size="controlSize" placeholder="仓库"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item label="地址" prop="address">
            <el-input v-model.lazy.trim="form.address" :size="controlSize" placeholder="地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话" prop="phone">
            <el-input v-model.trim="form.phone"
                      :size="controlSize"
                      placeholder="电话"
                      oninput="value=value.replace(/[^\d,-]/g, '')"
                      maxlength="12"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    layerInfo: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      controlSize: "mini",
      form: {
        name: "",
        warehouseName: "",
        address: "",
        phone: ""
      },
      originName: "",

      formRules: {
        name: [{required: true, message: "请填写名称", trigger: "blur"}],
        warehouseName: [{required: true, message: "请填写仓库", trigger: "blur"}],
        address: [{required: true, message: "请填写地址", trigger: "blur"}],
        phone: [
          {required: true, message: "请填写电话", trigger: "blur"},
          {min: 8, max: 12, message: "电话格式不正确", trigger: "blur"}
        ],
      },

      isLoading: false
    }
  },

  mounted() {
    this.layerInfo?.id && this.getDetail()
  },

  methods: {
    getDetail() {
      const detail = this.layerInfo
      this.originName = detail.name
      this.form = {
        name: detail.name,
        warehouseName: detail.wareName,
        address: detail.address,
        phone: detail.phone
      }
    },

    save() {
      this.$refs["userForm"].validate(async valid => {
        if (valid) {
          this.isLoading = true
          const sendData = {
            name: this.form.name,
            wareName: this.form.warehouseName,
            address: this.form.address,
            phone: this.form.phone
          }

          if (this.layerInfo?.id) {
            sendData.id = this.layerInfo.id
            this.originName === sendData.name && Reflect.deleteProperty(sendData, "name")
            const res = await this.$api.Base.Department.update(sendData).catch(() => {})
            this.isLoading = false
            if (res?.code === 200) {
              this.$message.success("修改成功");
              this.$emit("handle")
            } else {
              this.$message.error(res?.msg || "修改失败");
            }
          } else {
            const res = await this.$api.Base.Department.add(sendData).catch(() => {})
            this.isLoading = false
            if (res?.code === 200) {
              this.$message.success("新增成功");
              this.$refs["userForm"].resetFields();
            } else {
              this.$message.error(res?.msg || "新增失败");
            }
          }
        }
      })
    }
  }
}
</script>
